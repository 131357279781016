@import '~antd/dist/antd.css';
@import-normalize; /* bring in normalize.css styles */

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}
